import { render, staticRenderFns } from "./FooterSection.vue?vue&type=template&id=483f89e2&scoped=true&"
import script from "./FooterSection.vue?vue&type=script&lang=js&"
export * from "./FooterSection.vue?vue&type=script&lang=js&"
import style0 from "./FooterSection.vue?vue&type=style&index=0&id=483f89e2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "483f89e2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterHeading: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/footer/V2/FooterHeading.vue').default,BaseIcon: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseIcon.vue').default,FooterLine: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/footer/V2/FooterLine.vue').default})
